<template>
  <van-row class="page-wrapper">
    <template>
      <div class="support-off itserve-center">
        {{ $t("calculation.calculationHeader") }}
      </div>
      <van-col v-if="meterage === 1" class="flex-display-fix" span="24">
        <h3 class="tafiff-zones apply-loader itserve-center calculation-text">
          {{
            $t("calculation.zone1Meterage")
          }}
        </h3>
        <div>
          <h5 class="tafiff-zones calculation-text">{{ $t("calculation.kwt") }}: {{lowTarrif}} {{ $t("calculation.value")}}
            <!--                <br />{{ $t("calculation.highZone") }}: {{ highTarrif }} {{ $t("calculation.value")}}-->
          </h5>
        </div>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffOnePhaseData"
            disabled
        ></v-text-field>
        <van-col v-if="totalPayment > 0" class="flex-display-fix" span="24">
          <h5 class="tafiff-zones calculation-text">
            {{ $t("calculation.totalPayment")  }} {{ totalPayment }} {{ $t("calculation.value")}}
          </h5>
        </van-col>
        <div class="itserve-center">
          <itserve-button
              type="primary"
              :value="$t('calculation.calculate')"
              class="itserve-button-wide mt-15 apply-loader"
              @click="makeCalculations"
          ></itserve-button>
        </div>
      </van-col>
      <van-col v-else-if="meterage === 2" class="flex-display-fix" span="24">
        <h3 class="tafiff-zones itserve-center calculation-text">
          {{
            $t("calculation.zone2Meterage")
          }}
        </h3>
        <div>
          <h5 class="tafiff-zones calculation-text">{{ $t("calculation.kwt") }}: {{lowTarrif}} {{ $t("calculation.value")}}
            <!--            <br />{{ $t("calculation.highZone") }}: {{ highTarrif }} {{ $t("calculation.value")}}-->
            <br />{{ $t("calculation.nightZone") }}: 0.5
            <br />{{ $t("calculation.dayZone") }}: 1</h5>
        </div>
        <h5 class="tafiff-zones itserve-center calculation-text">
          23:00 - 7:00
        </h5>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffOnePhaseData"
            disabled
        ></v-text-field>
        <h5 class="tafiff-zones itserve-center calculation-text">
          07:00 - 23:00
        </h5>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousTwoPhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentTwoPhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffTwoPhaseData"
            disabled
        ></v-text-field>
        <van-col v-if="totalPayment > 0" class="flex-display-fix" span="24">
          <h5 class="tafiff-zones calculation-text">
            {{ $t("calculation.totalPayment")  }} {{ totalPayment }} {{ $t("calculation.value")}}
          </h5>
        </van-col>
        <div class="itserve-center">
          <itserve-button
              type="primary"
              :value="$t('calculation.calculate')"
              class="itserve-button-wide mt-15 apply-loader"
              @click="makeCalculations"
          ></itserve-button>
        </div>
      </van-col>
      <van-col v-else-if="meterage === 3" class="flex-display-fix" span="24">
        <h3 class="tafiff-zones itserve-center calculation-text">
          {{
            $t("calculation.zone3Meterage")
          }}
        </h3>
        <div>
          <h5 class="tafiff-zones calculation-text">{{ $t("calculation.kwt") }}: {{lowTarrif}} {{ $t("calculation.value")}}<br />
            <!--            {{ $t("calculation.highZone") }}: {{ highTarrif }} {{ $t("calculation.value")}}<br />-->
            {{ $t("calculation.nightZone") }}: 0.4<br />
            {{ $t("calculation.lowPickZone") }}: 1<br />
            {{ $t("calculation.pickZone") }}: 1.5</h5>
        </div>
        <h5 class="tafiff-zones itserve-center calculation-text">
          08:00 - 11:00, 20:00 - 22:00
        </h5>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffOnePhaseData"
            disabled
        ></v-text-field>
        <h5 class="tafiff-zones itserve-center calculation-text">
          7:00 - 8:00, 11:00 - 20:00, 22:00 - 23:00
        </h5>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousTwoPhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentTwoPhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffTwoPhaseData"
            disabled
        ></v-text-field>
        <h5 class="tafiff-zones itserve-center calculation-text">
          07:00 - 23:00
        </h5>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousThreePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentThreePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffThreePhaseData"
            disabled
        ></v-text-field>
        <van-col v-if="totalPayment > 0" class="flex-display-fix" span="24">
          <h5 class="tafiff-zones calculation-text">
            {{ $t("calculation.totalPayment")  }} {{ totalPayment }} {{ $t("calculation.value")}}
          </h5>
        </van-col>
        <div class="itserve-center">
          <itserve-button
              type="primary"
              :value="$t('calculation.calculate')"
              class="itserve-button-wide mt-15 apply-loader"
              @click="makeCalculations"
          ></itserve-button>
        </div>
      </van-col>
    </template>

  </van-row>
</template>
<script>
import ItserveButton from "@/components/forms/ItserveButton";
export default {
  components: {
    ItserveButton
  },
  name: "CalculationPage",
  data() {
    return {
      meterage: this.$route.params.meterage,
      // cities: [
      //    "Акимовка (Запорожская обл.)",
      //     "Александрия (Кировоградская обл.)",
      //     "Александровка (Кировоградская обл.)",
      //     "Алёшки",
      //     "Андрушевка (Житомирская обл.)",
      //     "Апостолово",
      //     "Ахтырка",
      //     "Балаклея (Харьковская обл.)",
      //     "Бар",
      //     "Барановка",
      //     "Барышевка",
      //     "Бахмач",
      //     "Баштанка",
      //     "Белая Церковь",
      //     "Белогорье",
      //     "Белозёрка (Херсонская обл.)",
      //     "Белополье (Сумская обл.)",
      //     "Бердичев",
      //     "Бердянск",
      //     "Берегово",
      //     "Бережаны",
      //     "Березанка (Николаевская обл.)",
      //     "Березнеговатое",
      //     "Березно",
      //     "Берислав",
      //     "Бершадь",
      //     "Бобринец",
      //     "Бобровица",
      //     "Богодухов",
      //     "Богородчаны",
      //     "Богуслав (Киевская обл.)",
      //     "Борзна",
      //     "Борисполь",
      //     "Бородянка",
      //     "Борщёв",
      //     "Боярка (Киевская обл.)",
      //     "Братское",
      //     "Бровары",
      //     "Брусилов",
      //     "Бурынь",
      //     "Буск",
      //     "Бучач",
      //     "Варва",
      //     "Васильковка",
      //     "Васильков (Киевская обл.)",
      //     "Ватутино (Харьковская обл.)",
      //     "Великая Александровка (Херсонская обл.)",
      //     "Великая Лепетиха",
      //     "Верхнеднепровск",
      //     "Верхний Рогачик",
      //     "Верховцево",
      //     "Веселиново",
      //     "Вижница",
      //     "Винница",
      //     "Виноградов",
      //     "Виньковцы",
      //     "Вишневое (Днепропетровская обл.)",
      //     "Владимир-Волынский",
      //     "Вознесенск",
      //     "Волочиск",
      //     "Волчанск",
      //     "Вольногорск",
      //     "Вольнянск",
      //     "Врадиевка",
      //     "Высокополье",
      //     "Вышгород",
      //     "Гадяч",
      //     "Гайсин",
      //     "Галич",
      //     "Геническ",
      //     "Герца",
      //     "Глобино",
      //     "Глухов (Сумская обл.)",
      //     "Глыбокая",
      //     "Голая Пристань",
      //     "Городенка",
      //     "Городище (Черкасская обл.)",
      //     "Городня",
      //     "Городок (Хмельницкая обл.)",
      //     "Горохов (Волынская обл.)",
      //     "Гоща",
      //     "Грушовка",
      //     "Гусятин (Тернопольская обл.)",
      //     "Деражня",
      //     "Дергачи",
      //     "Диканька",
      //     "Днепр",
      //     "Днепровское (Днепропетровская обл.)",
      //     "Долина (Ивано-Франковская обл.)",
      //     "Долинская",
      //     "Доманёвка",
      //     "Драбов",
      //     "Дрогобыч",
      //     "Дубно",
      //     "Дубовый Гай",
      //     "Дунаевцы",
      //     "Еланец",
      //     "Емильчино",
      //     "Жашков",
      //     "Жёлтые Воды",
      //     "Житомир",
      //     "Жмеринка",
      //     "Залещики",
      //     "Запорожье",
      //     "Заставна",
      //     "Збараж",
      //     "Зборов",
      //     "Звенигородка",
      //     "Згуровка",
      //     "Здолбунов",
      //     "Зеленодольск",
      //     "Зеньков (Полтавская область)",
      //     "Змиёв",
      //     "Знаменка (Кировоградская обл.)",
      //     "Золотоноша",
      //     "Иванков (Киевская обл.)",
      //     "Ивановка (Херсонская обл.)",
      //     "Ивано-Франковск",
      //     "Изюм",
      //     "Изяслав",
      //     "Ильинцы (Винницкая обл.)",
      //     "Ирпень",
      //     "Иршава",
      //     "Иршанск",
      //     "Ичня",
      //     "Кагарлык",
      //     "Казанка",
      //     "Казатин",
      //     "Каланчак",
      //     "Калиновка (Винницкая обл.)",
      //     "Калуш",
      //     "Каменец-Подольский",
      //     "Каменка-Днепровская",
      //     "Каменка (Черкасская обл.)",
      //     "Каменское (Днепропетровская обл.)",
      //     "Камень-Каширский",
      //     "Канев",
      //     "Карловка",
      //     "Катеринополь",
      //     "Каховка",
      //     "Кельменцы",
      //     "Киверцы",
      //     "Киев",
      //     "Кицмань",
      //     "Кобеляки",
      //     "Ковель",
      //     "Козелец",
      //     "Козова",
      //     "Коломыя",
      //     "Конотоп (Сумская обл.)",
      //     "Константиновка (Донецкая обл.)",
      //     "Короп",
      //     "Коростень",
      //     "Коростышев",
      //     "Корсунь-Шевченковский",
      //     "Корюковка",
      //     "Косов",
      //     "Костополь",
      //     "Краматорск",
      //     "Красилов",
      //     "Красноград",
      //     "Краснополье (Сумская обл.)",
      //     "Кременец",
      //     "Кременчук",
      //     "Кривой Рог",
      //     "Кринички (Днепропетровская обл.)",
      //     "Кролевец",
      //     "Кропивницкий",
      //     "Куликовка",
      //     "Купянск",
      //     "Ладыжинка",
      //     "Лановцы",
      //     "Лебедин (Сумская обл.)",
      //     "Летичев",
      //     "Липовая Долина",
      //     "Липовец (Винницкая обл.)",
      //     "Лисичанск",
      //     "Литин",
      //     "Лозовая",
      //     "Локачи",
      //     "Лохвица",
      //     "Лубны",
      //     "Лугины",
      //     "Луцк",
      //     "Лысянка",
      //     "Львов",
      //     "Любар",
      //     "Магдалиновка",
      //     "Макаров",
      //     "Малая Виска",
      //     "Малин",
      //     "Марганец",
      //     "Мариуполь",
      //     "Межгорье",
      //     "Мелитополь",
      //     "Мена",
      //     "Мерефа",
      //     "Миргород",
      //     "Мироновка (Киевская обл.)",
      //     "Михайловка (Запорожская обл.)",
      //     "Млинов",
      //     "Могилёв-Подольский",
      //     "Монастыриска",
      //     "Монастырище",
      //     "Мукачево",
      //     "Надворная",
      //     "Народичи",
      //     "Недригайлов",
      //     "Нежин",
      //     "Немиров (Винницкая обл.)",
      //     "Николаев",
      //     "Никополь (Днепропетровская обл.)",
      //     "Новая Водолага",
      //     "Новая Каховка",
      //     "Новая Одесса",
      //     "Новая Ушица",
      //     "Новгородка",
      //     "Новгород-Северский",
      //     "Новоархангельск",
      //     "Нововолынск",
      //     "Нововоронцовка",
      //     "Новоград-Волынский",
      //     "Новомиргород",
      //     "Новомосковск",
      //     "Новоселица (Черновицкая обл.)",
      //     "Новотроицкое (Херсонская обл.)",
      //     "Новые Санжары (Полтавская обл.)",
      //     "Новый Буг",
      //     "Носовка",
      //     "Обухов",
      //     "Одесса",
      //     "Олевск",
      //     "Ольшанское",
      //     "Онуфриевка",
      //     "Оратов",
      //     "Орехов",
      //     "Очаков",
      //     "Павлоград",
      //     "Первомайск (Николаевская обл.)",
      //     "Первомайское (Днепропетровская обл.)",
      //     "Перечин",
      //     "Переяслав",
      //     "Першотравенск (Днепропетровская обл.)",
      //     "Петрово (Кировоградская обл.)",
      //     "Побугское",
      //     "Погребыще",
      //     "Подволочиск",
      //     "Подгайцы (Тернопольская обл.)",
      //     "Покров",
      //     "Покровское (Днепропетровская обл.)",
      //     "Полонное",
      //     "Полтава",
      //     "Попельня",
      //     "Прилуки",
      //     "Просяная",
      //     "Пулины",
      //     "Пустомыты",
      //     "Путивль",
      //     "Пятихатки (Днепропетровская обл.)",
      //     "Радивилов",
      //     "Радомышль",
      //     "Ратно",
      //     "Репки",
      //     "Решетиловка",
      //     "Ровно",
      //     "Рогатин",
      //     "Рожище",
      //     "Рожнятов",
      //     "Рокитное (Киевская обл.)",
      //     "Романов",
      //     "Ромны",
      //     "Ружин",
      //     "Самбор",
      //     "Сарны",
      //     "Свалява",
      //     "Светловодск",
      //     "Семёновка (Черниговская обл.)",
      //     "Синельниково",
      //     "Скадовск",
      //     "Сквира",
      //     "Славута",
      //     "Смела",
      //     "Смолино",
      //     "Снигирёвка",
      //     "Сновск",
      //     "Снятын",
      //     "Сокиряны",
      //     "Солёное",
      //     "Сосница",
      //     "Софиевка (Днепропетровская обл.)",
      //     "Сребное",
      //     "Ставище (Киевская обл.)",
      //     "Старая Синява",
      //     "Староконстантинов",
      //     "Сторожинец",
      //     "Стрый",
      //     "Сумы",
      //     "Талалаевка",
      //     "Тальное",
      //     "Тараща",
      //     "Теофиполь",
      //     "Теплик",
      //     "Теребовля",
      //     "Терновка (Днепропетровская обл.)",
      //     "Тернополь",
      //     "Тетиев",
      //     "Тлумач",
      //     "Токмак",
      //     "Томаковка",
      //     "Томашполь",
      //     "Тростянец (Винницкая обл.)",
      //     "Тростянец (Сумская обл.)",
      //     "Тульчин",
      //     "Тывров",
      //     "Тысменица",
      //     "Тячев",
      //     "Ужгород",
      //     "Умань",
      //     "Фастов",
      //     "Харьков",
      //     "Херсон",
      //     "Хмельник",
      //     "Хмельницкий",
      //     "Хотин",
      //     "Хуст",
      //     "Царичанка",
      //     "Чаплинка (Херсонская обл.)",
      //     "Чемеровцы",
      //     "Червоноград",
      //     "Черкассы",
      //     "Чернигов",
      //     "Чернобай",
      //     "Черновцы",
      //     "Чигирин",
      //     "Чортков (Тернопольская обл.)",
      //     "Чугуев",
      //     "Чуднов",
      //     "Чутово",
      //     "Шаргород",
      //     "Шепетовка",
      //     "Широкое (Днепропетровская обл.)",
      //     "Шостка",
      //     "Шпола",
      //     "Шумск",
      //     "Юрьевка",
      //     "Яворов",
      //     "Яготин",
      //     "Ярмолинцы (Хмельницкая обл.)",
      //     "Все равно",
      // ],
      previousOnePhaseData: 0,
      currentOnePhaseData: 0,
      diffOnePhaseData: 0,
      previousTwoPhaseData: 0,
      currentTwoPhaseData: 0,
      diffTwoPhaseData: 0,
      previousThreePhaseData: 0,
      currentThreePhaseData: 0,
      diffThreePhaseData: 0,
      totalPayment: 0,
      lowTarrif: process.env.VUE_APP_LOW_TARIFS,
      highTarrif: process.env.VUE_APP_HIGH_TARIFS,
    };
  },
  computed: {

  },
  watch: {
    previousOnePhaseData(){
      this.diffOnePhaseData = this.currentOnePhaseData - this.previousOnePhaseData;
    },
    currentOnePhaseData(){
      this.diffOnePhaseData = this.currentOnePhaseData - this.previousOnePhaseData;
    },
    previousTwoPhaseData(){
      this.diffTwoPhaseData = this.currentTwoPhaseData - this.previousTwoPhaseData;
    },
    currentTwoPhaseData(){
      this.diffTwoPhaseData = this.currentTwoPhaseData - this.previousTwoPhaseData;
    },
    previousThreePhaseData(){
      this.diffThreePhaseData = this.currentThreePhaseData - this.previousThreePhaseData;
    },
    currentThreePhaseData(){
      this.diffThreePhaseData = this.currentThreePhaseData - this.previousThreePhaseData;
    },
    // totalPayment(){
    //   this.totalPayment();
    // }
  },
  created() {
  },
  destroyed() { },
  methods: {
    makeCalculations(){
      let tariff;
      if(this.diffOnePhaseData < 250){
        tariff = process.env.VUE_APP_LOW_TARIFS;
      }
      else{
        tariff = process.env.VUE_APP_HIGH_TARIFS;
      }
      if(this.meterage === 1){
        this.totalPayment = this.diffOnePhaseData * tariff;
      }
      else if(this.meterage === 2){
        this.totalPayment = (this.diffOnePhaseData * tariff * 0.5) + (this.diffTwoPhaseData * tariff);
      }
      else if(this.meterage === 3){
        this.totalPayment = (this.diffOnePhaseData * tariff * 1.5) + (this.diffTwoPhaseData * tariff) + (this.diffThreePhaseData * tariff * 0.4);
      }
    }
  },
};
</script>
<style>
.v-text-field input {
  text-align: left;
  text-align: -moz-left;
  text-align: -webkit-left;
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #777777;
  outline: none;
  font-size: 16px;
  padding: 0 10px 0 10px;
  max-width: 337px;
  box-sizing: border-box;
  background-color: #fff;
}

.count-zones {
  display: inline-block;
  padding: 5px 28px 0 28px;
}
.tafiff-zones {
  display: inline-block;
  padding: 0 28px 0 28px;
}
.support-off {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
